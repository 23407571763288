import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/providers/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/layouts/root/root-layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../assets/fonts/openSans/openSans-light.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/openSans/openSans-regular.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/openSans/openSans-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"openSans\"}");
