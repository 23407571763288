'use client';

import { useEffect, useState } from 'react';

import posthog from 'posthog-js';

import { toClassName } from 'helpers/format';

import { useTranslation } from 'hooks/use-translation';

import { Button } from 'components/button';
import Icon from '@repo/ui/icon';

type TProps = {
  locale: string;
};

export const cookieConsentGiven = (): any => {
  if (!localStorage.getItem('cookie_consent')) {
    return 'undecided';
  }
  return localStorage.getItem('cookie_consent');
};

const CookieConsent = (props: TProps) => {
  const { locale } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);

  const { t } = useTranslation(locale);

  const [consentGiven, setConsentGiven] = useState('');

  const handleClickAccept = () => {
    setIsOpen(false);

    localStorage.setItem('cookie_consent', 'yes');
    setConsentGiven('yes');

    setTimeout(() => {
      setHide(true);
    }, 700);

    posthog.opt_in_capturing();
  };

  const handleClickDecline = () => {
    setIsOpen(false);

    localStorage.setItem('cookie_consent', 'no');
    setConsentGiven('no');

    setTimeout(() => {
      setHide(true);
    }, 700);

    posthog.opt_out_capturing();
  };

  useEffect(() => {
    setConsentGiven(cookieConsentGiven());
  }, []);

  useEffect(() => {
    if (consentGiven !== '') {
      posthog.set_config({
        persistence: consentGiven === 'yes' ? 'localStorage+cookie' : 'memory',
      });
    }
  }, [consentGiven]);

  useEffect(() => {
    if (cookieConsentGiven() === 'undecided') {
      setIsOpen(true);
    }
  }, []);

  return (
    <div
      className={toClassName(
        'fixed z-[200] bottom-0 left-0 right-0 sm:left-4 sm:bottom-4 w-full sm:max-w-md duration-700',
        !isOpen
          ? 'transition-[opacity,transform] translate-y-8 opacity-0 pointer-events-none'
          : 'transition-[opacity,transform] translate-y-0 opacity-100 pointer-events-auto',
        hide && 'hidden',
      )}
    >
      <div className="dark:bg-card bg-background rounded-md m-3 border border-border shadow-lg bg-white">
        <div className="grid gap-2">
          <div className="border-b border-border h-14 flex items-center justify-between p-4">
            <h1 className="text-lg font-medium">
              {t('component.cookieConsent.title')}
            </h1>
            <Icon accessor="cookie" className="h-[1.2rem] w-[1.2rem]" />
          </div>
          <div className="p-4">
            <p className="text-sm font-normal text-start">
              {t('component.cookieConsent.description')}
              <br />
            </p>
          </div>
          <div className="flex gap-2 p-4 py-5 border-t border-border">
            <Button
              onClick={handleClickAccept}
              className="w-full bg-primary-500 hover:bg-primary-500/80 "
            >
              {t('component.cookieConsent.label.accept')}
            </Button>
            <Button
              onClick={handleClickDecline}
              className="w-full bg-secondary-100 !text-secondary-400 hover:bg-secondary-200/80"
              // variant="secondary"
            >
              {t('component.cookieConsent.label.decline')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
