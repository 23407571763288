'use client';

import { useEffect } from 'react';

import dynamic from 'next/dynamic';

import { PostHogProvider } from 'posthog-js/react';

import posthog from 'posthog-js';

import { cookieConsentGiven } from 'components/cookie-consent/cookie-consent';

const PostHogPageView = dynamic(
  () => import('components/posthog-pageview/posthog-pageview'),
  {
    ssr: false,
  },
);

type TProps = {
  children: any;
};

const CSPostHogProvider = (props: TProps) => {
  const { children } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST as string,
        person_profiles: 'always',
        capture_pageview: true,
        capture_pageleave: true,
        persistence:
          cookieConsentGiven() === 'yes' ? 'localStorage+cookie' : 'memory',
      });
    }
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <PostHogPageView />
      {children}
    </PostHogProvider>
  );
};

export default CSPostHogProvider;
