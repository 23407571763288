'use client';

import PictureViewerProvider from 'providers/picture-viewer-provider';
import CSPostHogProvider from 'providers/posthog';
import ToastProvider from 'providers/toast-provider';

import CookieConsent from 'components/cookie-consent';

type TProps = {
  children: any;
  locale: string;
};

const Providers = (props: TProps) => {
  const { children, locale } = props;

  return (
    <CSPostHogProvider>
      <PictureViewerProvider>
        {children}
        <CookieConsent locale={locale} />
        <ToastProvider />
      </PictureViewerProvider>
    </CSPostHogProvider>
  );
};

export default Providers;
