'use client';

import { createContext, FC, useContext, useState } from 'react';

import Image from 'components/nextjs-fixes/image';

import Icon from '@repo/ui/icon';

import { toClassName } from 'helpers/format';
import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { useKeyPress } from 'hooks/use-key-press';

import { styles } from '.';

type TContext = {
  setPictures: (pictures: any[]) => void;

  selector: number | null;
  setSelector: (selector: number | null) => void;
};

type TProps = {
  children: any;
};

const Context = createContext<TContext>({
  setPictures: () => null,

  selector: null,
  setSelector: () => null,
}) as any;

const PictureViewerProvider: FC<TProps> = (props) => {
  const { children } = props;

  const [pictures, setPictures] = useState<any>([]);
  const [selector, setSelector] = useState<number | null>(null);

  useKeyPress('ArrowLeft', () => {
    if (selector === null) {
      return;
    }

    if (selector === 0) {
      return;
    }

    setSelector(selector - 1);
  });

  useKeyPress('ArrowRight', () => {
    if (selector === null) {
      return;
    }

    if (selector === pictures.length - 1) {
      return;
    }

    setSelector(selector + 1);
  });

  useKeyPress('Escape', () => {
    if (selector === null) {
      return;
    }

    setSelector(null);
  });

  return (
    <Context.Provider
      value={{
        setPictures,

        selector,
        setSelector,
      }}
    >
      {children}
      <div className="relative">
        {selector !== null && (
          <div className={styles.viewer.container}>
            <div className={styles.viewer.content}>
              <Image
                src={handleUseStrapiImageUrl(
                  (pictures[selector] as any)?.url ?? '',
                )}
                alt={(pictures[selector] as any)?.alternativeText ?? ''}
                layout="fill"
                objectFit="contain"
                className="pointer-events-none translate-y-10 sm:translate-y-0"
              />

              <div className={styles.viewer.navigation.container}>
                <div className={styles.viewer.navigation.head}>
                  {/* <div className={styles.viewer.navigation.content}>
                  <p className={styles.viewer.navigation.alt}>
                    {
                      pictures.data[selector]?.image?.data.attributes
                        .caption
                    }
                  </p>
                  <p className={styles.viewer.navigation.date}>
                    {new Date(
                      pictures.data[selector]?.image?.data.attributes
                        .createdAt as any,
                    ).toLocaleDateString(lang)}
                  </p>
                </div> */}
                  <button
                    className={styles.viewer.button}
                    onClick={() => setSelector(null)}
                  >
                    <Icon accessor="xmark" />
                  </button>
                </div>
                <div className={styles.viewer.navigation.body}>
                  <button
                    className={toClassName(
                      styles.viewer.button,
                      selector === 0 && 'opacity-20',
                    )}
                    disabled={selector === 0}
                    onClick={() => setSelector(selector - 1)}
                  >
                    <Icon accessor="chevron-left" />
                  </button>
                  <button
                    className={toClassName(
                      styles.viewer.button,
                      selector === pictures.length - 1 && 'opacity-20',
                    )}
                    disabled={selector === pictures.length - 1}
                    onClick={() => setSelector(selector + 1)}
                  >
                    <Icon accessor="chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Context.Provider>
  );
};

export const usePictureViewerContext: any = () => useContext(Context);

export default PictureViewerProvider as any;
