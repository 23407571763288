"use client";

import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { toClassName } from "helpers/format";
import { X } from "lucide-react";
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactElement,
} from "react";

const X_: any = X;

const ToastPrimitives_: any = ToastPrimitives;

const ToastProvider: any = ToastPrimitives_.Provider;

const ToastViewport: any = forwardRef<any, any>(
  ({ className, ...props }, ref) => (
    <ToastPrimitives_.Viewport
      ref={ref}
      className={toClassName(
        "fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
        className
      )}
      {...props}
    />
  )
);
ToastViewport.displayName = ToastPrimitives_.Viewport.displayName;

const toastVariants = cva(
  "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "border bg-background text-foreground",
        destructive:
          "destructive group border-destructive bg-destructive text-destructive-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Toast: any = forwardRef<
  ElementRef<typeof ToastPrimitives_.Root>,
  ComponentPropsWithoutRef<typeof ToastPrimitives_.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives_.Root
      ref={ref}
      className={toClassName(toastVariants({ variant }), className)}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives_.Root.displayName;

const ToastAction: any = forwardRef<
  ElementRef<typeof ToastPrimitives_.Action>,
  ComponentPropsWithoutRef<typeof ToastPrimitives_.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives_.Action
    ref={ref}
    className={toClassName(
      "inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive",
      className
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives_.Action.displayName;

const ToastClose: any = forwardRef<
  ElementRef<typeof ToastPrimitives_.Close>,
  ComponentPropsWithoutRef<typeof ToastPrimitives_.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives_.Close
    ref={ref}
    className={toClassName(
      "absolute right-2 top-2 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      className
    )}
    toast-close=""
    {...props}
  >
    <X_ className="h-4 w-4" />
  </ToastPrimitives_.Close>
));
ToastClose.displayName = ToastPrimitives_.Close.displayName;

const ToastTitle: any = forwardRef<
  ElementRef<typeof ToastPrimitives_.Title>,
  ComponentPropsWithoutRef<typeof ToastPrimitives_.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives_.Title
    ref={ref}
    className={toClassName("text-sm font-semibold", className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives_.Title.displayName;

const ToastDescription: any = forwardRef<
  ElementRef<typeof ToastPrimitives_.Description>,
  ComponentPropsWithoutRef<typeof ToastPrimitives_.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives_.Description
    ref={ref}
    className={toClassName("text-sm opacity-90", className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives_.Description.displayName;

type ToastProps = ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
